<template>
    <div>
        <div style="max-width: 800px">
            <v-stepper
                v-model="step"
                vertical
                class="elevation-0"
            >
                <v-stepper-step
                    step="1"
                    :complete="step > 1"
                >
                    Select Dataset
                </v-stepper-step>

                <v-stepper-content step="1">
                    <v-alert
                        v-if="dataset"
                        color="blue-grey lighten-4"
                        dense
                        icon="fa-file-csv"
                        prominent
                    >
                        <h4>{{ dataset.name }}</h4>
                    </v-alert>
                    <dataset-selector v-model="dataset" />
                </v-stepper-content>

                <v-stepper-step
                    step="2"
                    :complete="step > 2"
                >
                    Tag Columns
                </v-stepper-step>

                <v-stepper-content step="2">
                    <v-card
                        color="grey lighten-3"
                        class="mb-12"
                        height="200px"
                    >
                        <v-card-text v-if="!processingFile">
                            <p>
                                Map columns from source file.
                            </p>
                            <v-row>
                                <v-col cols=6 v-for="col in columns" :key="col.key">
                                    <v-select
                                        v-model="columnMap[col.key]"
                                        :items="sourceColumns"
                                        outlined
                                        dense
                                        persistent-hint
                                        :label="col.label"
                                        :rules="columnMapSelectorRules"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-progress-circular v-if="processingFile"
                                             indeterminate
                                             color="primary"
                                             class="text-center"
                        />
                    </v-card>
                    <v-btn
                        color="primary"
                        @click="uploadFile"
                        :disabled="!readyToRequest"
                    >
                        COMPUTE
                    </v-btn>
                    <v-btn text @click="step = 1">
                        Cancel
                    </v-btn>
                </v-stepper-content>
            </v-stepper>
        </div>
    </div>
</template>

<script>
    import DatasetSelector from '@/components/DatasetSelector.vue';
    import webapi from '@/core/webapi';
    import CONSTANTS from '@/constants';

    const URI = `${CONSTANTS.API_BASE}/report/models/rfm`;

    export default {
        name: 'ManageRfmModel',

        components: {
            DatasetSelector,
        },

        data: () => ({
            step: undefined,
            dataset: undefined,

            columns: [
                { label: 'Customer ID', key: 'CUSTOMER_ID' },
                { label: 'Order ID', key: 'ORDER_ID' },
                { label: 'Order Date', key: 'ORDER_CREATED_AT' },
                { label: 'Order Revenue', key: 'ORDER_REVENUE' },
            ],
            columnMap: {
                CUSTOMER_ID: undefined,
                ORDER_ID: undefined,
                ORDER_CREATED_AT: undefined,
                ORDER_REVENUE: undefined,
            },
            sourceColumns: [],

            processingFile: false,

            columnMapSelectorRules: [
                (v) => !!v || 'Column needs to be set',
                (v) => !!v || 'Column needs to be set.',
            ],

            result: undefined,
        }),

        computed: {
            readyToRequest() {
                return (
                    this.dataset !== undefined
                    && this.columnMap.CUSTOMER_ID !== undefined
                    && this.columnMap.ORDER_ID !== undefined
                    && this.columnMap.ORDER_CREATED_AT !== undefined
                    && this.columnMap.ORDER_REVENUE !== undefined
                );
            },
        },

        watch: {
            dataset(newValue) {
                if (newValue !== undefined) {
                    const { dtypes } = newValue.metadata;
                    this.sourceColumns = dtypes ? Object.keys(dtypes) : [];

                    if ((this.step === undefined) || (this.step <= 2)) {
                        this.step = 2;
                    }
                }
            },
        },

        methods: {
            onSelectedFilesChange(files) {
                this.selectedFiles = files;
            },
            uploadFile() {
                this.requestRFM(this.dataset.id, this.columnMap).then((response) => {
                    this.result = response;
                    this.$emit('on-result', response);
                }).catch((err) => {
                    this.$helpers.notifyError(`RFM Error. Reason: ${err}`);
                });
            },
            async requestRFM(fileId, columnMap) {
                const uri = `${URI}`;

                const body = {
                    dataset_id: fileId,
                    column_map: columnMap,
                };

                return webapi.post({ uri, body });
            },

        },
    };
</script>
